import axios, { AxiosError, AxiosInstance } from 'axios';
import * as Sentry from '@sentry/react';
import { jwtDecode } from 'jwt-decode';

const apiBaseUrl = import.meta.env.VITE_API_URL;

interface DecodedToken {
  aud: string;
  iss: string;
  exp: number;
  nbf: number;
  tfp: string;
  scp: string;
  azpacr: string;
  sub: string;
  oid: string;
  tid: string;
  ver: string;
  azp: string;
  iat: number;
}

export class HttpService {
  private baseUrl: string;
  private axiosInstance: AxiosInstance;
  private token: string | null = null;
  private tokenExpirationTime: number | null = null;
  private fetchingToken: Promise<void> | null = null;

  constructor(baseUrl?: string) {
    this.baseUrl = baseUrl || apiBaseUrl;
    this.axiosInstance = axios.create();

    // Attach token interceptor
    this.axiosInstance.interceptors.request.use(
      async (config) => {
        const token = await this.getToken();
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
  }

  private isTokenValid(): boolean {
    if (!this.token || !this.tokenExpirationTime) return false;
    // Add a 60-second buffer to ensure we don't use a token that's about to expire
    return Date.now() < this.tokenExpirationTime - 60000;
  }

  private async getToken(): Promise<string | null> {
    if (!this.isTokenValid()) {
      await this.ensureValidToken();
    }
    return this.token;
  }

  private async ensureValidToken() {
    if (this.isTokenValid()) {
      return;
    }

    if (this.fetchingToken) {
      return this.fetchingToken;
    }

    this.fetchingToken = this.fetchToken();
    try {
      await this.fetchingToken;
    } finally {
      this.fetchingToken = null;
    }
  }

  private async fetchToken() {
    try {
      console.log("Fetching new token");
      const response = await axios.post(`${this.baseUrl}/api/auth/service-token`);
      this.token = response.data;
      if (this.token) {
        const decodedToken = jwtDecode<DecodedToken>(this.token);
        this.tokenExpirationTime = decodedToken.exp * 1000; // Convert to milliseconds
        console.log("Token fetched and cached");
      } else {
        console.error('Received empty token from server');
      }
    } catch (error) {
      console.error('Error fetching token:', error);
      throw error;
    }
  }

  private handleError(error: any, url: string, method: string, data?: any): Error {
    console.error(`Error in HttpService ${method} ${url}:`, error);

    let enhancedError: Error;
    if (axios.isAxiosError(error)) {
      const status = error.response?.status || 'Unknown';
      const message = error.message;
      const headers = error.config?.headers;

      enhancedError = new Error(
        `${status} Error: ${method} request failed for URL ${this.baseUrl}/${url} with message: ${message}`
      );

      Sentry.captureException(enhancedError, {
        extra: {
          url: `${this.baseUrl}/${url}`,
          method,
          data,
          status,
          headers,
        },
      });
    } else {
      enhancedError = new Error(`Unknown Error: ${method} request failed for URL ${this.baseUrl}/${url} with message: ${error.message}`);
      Sentry.captureException(enhancedError);
    }

    // Include the original error stack trace for better debugging
    console.error(error.stack);

    // Return the enhanced error to be thrown in the catch block
    return enhancedError;
  }

  async get(url: string) {
    console.log("HttpService GET " + `${this.baseUrl}/${url}`);

    try {
      const response = await this.axiosInstance.get(`${this.baseUrl}/${url}`);
      console.log(response.data);
      return response.data;
    } catch (error) {
      throw this.handleError(error as AxiosError, url, 'GET');
    }
  }

  async post(url: string, data: any) {
    console.log("HttpService POST " + `${this.baseUrl}/${url}`);
    console.log(data);

    try {
      const response = await this.axiosInstance.post(`${this.baseUrl}/${url}`, data);
      console.log(response.data);
      return response.data;
    } catch (error) {
      throw this.handleError(error as AxiosError, url, 'POST', data);
    }
  }
   
  async put(url: string, data: any) {
    console.log("HttpService PUT " + `${this.baseUrl}/${url}`);
    console.log(data);

    try {
      const response = await this.axiosInstance.put(`${this.baseUrl}/${url}`, data);
      console.log(response.data);
      return response.data;
    } catch (error) {
      throw this.handleError(error as AxiosError, url, 'PUT', data);
    }
  }

  async delete(url: string) {
    console.log("HttpService DELETE " + `${this.baseUrl}/${url}`);

    try {
      const response = await this.axiosInstance.delete(`${this.baseUrl}/${url}`);
      console.log(response.data);
      return response.data;
    } catch (error) {
      throw this.handleError(error as AxiosError, url, 'DELETE');
    }
  }
}